<template>
  <form @submit.prevent="submitForm">
    <input
      class="form-control"
      type="file"
      ref="uploadInput"
      multiple
      @change="updateFile($event)"
      id="formFile"
    />
    <button type="submit" class="btn btn-primary btn-lg">确认提交</button>
  </form>
</template>

<script>
import { getUploadInfo, userDetail } from "@/network/API.js";
import COS from "cos-js-sdk-v5";
import tools from "../utils/tools";
export default {
  data() {
    return {
      userInfo: {},
      cos: null,
      uploadFiles: [],
      loader: null,
    };
  },
  async created() {
    console.log("created");
    this.userInfo = await userDetail();
    this.cos = new COS({
      getAuthorization: async (options, callback) => {
        let data = await getUploadInfo();
        let credentials = data.temp_key.credentials;
        let params = {
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: data.temp_key.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.temp_key.expiredTime, // 时间戳，单位秒，如：1580000000
        };
        console.log(params);
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: data.temp_key.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.temp_key.expiredTime, // 时间戳，单位秒，如：1580000000
        });
      },
    });
  },
  methods: {
    // createTransferManager() {
    //   // 创建一个 COSClient 实例，这是访问 COS 服务的基础实例。
    //   // 详细代码参见本页: 简单操作 -> 创建 COSClient

    //   var cosClient = createCOSClient();

    //   // 自定义线程池大小，建议在客户端与 COS 网络充足（例如使用腾讯云的 CVM，同地域上传 COS）的情况下，设置成16或32即可，可较充分的利用网络资源
    //   // 对于使用公网传输且网络带宽质量不高的情况，建议减小该值，避免因网速过慢，造成请求超时。
    //   var threadPool = Executors.newFixedThreadPool(32);

    //   // 传入一个 threadpool, 若不传入线程池，默认 TransferManager 中会生成一个单线程的线程池。
    //   var transferManager = new TransferManager(cosClient, threadPool);

    //   // 设置高级接口的配置项
    //   // 分块上传阈值和分块大小分别为 5MB 和 1MB
    //   var transferManagerConfiguration = new TransferManagerConfiguration();
    //   transferManagerConfiguration.setMultipartUploadThreshold(
    //     300 * 1024 * 1024
    //   );
    //   transferManagerConfiguration.setMinimumUploadPartSize(5 * 1024 * 1024);
    //   transferManager.setConfiguration(transferManagerConfiguration);

    //   return transferManager;
    // },
    // //关闭
    // shutdownTransferManager(transferManager) {
    //   transferManager.shutdownNow(true);
    // },
    updateFile(event) {
      const files = event.target.files;
      let that = this;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.uploadFiles.push(file);
      }
      event.target.value = "";
    },
    async submitForm(event) {
      this.loader = this.$loading.show();
      for (let i = 0; i < this.uploadFiles.length; i++) {
        let res = await this.uploadFileToCloud(this.uploadFiles[i]);
        console.log("path is " + tools.cloudPathToLocal(res.Location));
      }
      this.loader.hide();
    },
    uploadFileToCloud(file) {
      console.log(`uploads/mem/doc/${this.userInfo.member_id}/${file.name}`);
      return new Promise((resolve, reject) => {
        this.cos.putObject(
          {
            Bucket: "votes-1301974940" /* 填入您自己的存储桶，必须字段 */,
            Region: "ap-beijing" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
            Key: `uploads/mem/doc/${this.userInfo.member_id}/${file.name}` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
            Body: file /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */,
            onTaskReady: function (taskId) {
              /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
              console.log(taskId);
            },
            // 支持自定义headers 非必须
            Headers: {
              "x-cos-meta-test": 123,
            },
          },
          function (err, data) {
            if (err) {
              console.log("upload err " + err);
              reject(`附件上传失败, ${err}`);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
  },
};
</script>

<style></style>
